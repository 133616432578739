import React from 'react';
import AxiosRequest from '../apis';

// images_folders_requests
const currentApis = ['images/v1/folders'];

const ImageFoldersRequestGet = ({ requestCount, reqParams, responseDataHandle }) => {
  // 图片文件夹请求 Get
  const api = currentApis[0];
  const method = 'get';
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
ImageFoldersRequestGet.defaultProps = {
  requestCount: 0,
  reqParams: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const ImageFoldersRequestPost = ({ requestCount, reqObject, responseDataHandle }) => {
  // 图片文件夹请求 post
  const api = [].concat(currentApis)[0];
  const method = 'post';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
ImageFoldersRequestPost.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

export { ImageFoldersRequestGet, ImageFoldersRequestPost };
