import React, { useState, useEffect, useRef } from 'react';
import { message, Row, Col, Button } from 'antd';

import { ImageFoldersRequestGet, ImageFoldersRequestPost } from './images_folders_requests';
import FoldersLists from './folders_lists';
import PathBreadcrumbs from './path_breadcrumb';
import CreateFolderModal from './folder_create';

const findFolderId = (folders, folderName) => {
  // 查找folder id
  for (let index = 0; index < folders.length; index++) {
    const element = folders[index];
    if (element.folder_name === folderName) {
      return element.id;
    }
  }
  return 0;
}

// 图片和文件夹列表
const ImagesFoldersLists = () => {
  const [createFolderModalState, setCreateFolderModalState] = useState(false);

  const [foldersLists, setFoldersLists] = useState([]);
  const [parentFolder, setParentFolder] = useState('');

  const [foldersListsRequestCount, setFoldersListsRequestCount] = useState(0);

  const foldersListsResponseHandle = (respObj) => {
    // 更新文件夹数组
    if (Object.keys(respObj).includes('results')
      && Array.isArray(respObj.results)) {
      setFoldersLists(respObj.results);
      console.log(respObj.results);
    }else {
      message.error('网络错误...');
    }
  }

  const childFolderChangedHandle = (folderId, childFolderFullName) => {
    // 子文件夹改变后操作 重新读取文件
    setFoldersListsRequestCount(foldersListsRequestCount + 1);
  };

  const folderCreatedHandle = (newfolderName) => {
    // 新建文件夹后操作 重新读取文件
    console.log('Created  folder ' + newfolderName);
    setFoldersListsRequestCount(foldersListsRequestCount + 1);
  }

  useEffect(() => {
    // 初始化文件夹列表
    setFoldersListsRequestCount(foldersListsRequestCount + 1);
  }, []);

  const folderDoubleClickHandle = (folderId, childFolderFullName, folderShowName) => {
    // 文件夹双击打开该文件夹
    const doubeClickFolderId = findFolderId(foldersLists, childFolderFullName);
    if (doubeClickFolderId > 0) {
      console.log(childFolderFullName);
      setParentFolder(childFolderFullName);
    } else {
      // 没找到子文件夹
      console.log('文件系统错误...');
      message.error('文件系统错误...');
    }
  }

  return (
    <div>
      <CreateFolderModal
        parentFolderFullPath={parentFolder}
        visible={createFolderModalState}
        setVisible={setCreateFolderModalState}
        createdHandle={folderCreatedHandle}></CreateFolderModal>

      <ImageFoldersRequestGet requestCount={foldersListsRequestCount}
        reqParams={{}}
        responseDataHandle={foldersListsResponseHandle}
      ></ImageFoldersRequestGet>
      <PathBreadcrumbs folderFullPath={parentFolder} clickPathHandle={setParentFolder}></PathBreadcrumbs>
      <Row>
        <Col span={12}><h6>图片列表</h6></Col>
        <Col span={12}>
          <Button type="link"
            onClick={() => setCreateFolderModalState(true)}
            style={{float: 'right'}}
          >新建文件夹</Button>
        </Col>
      </Row>
      <ImageFoldersRequestPost></ImageFoldersRequestPost>
      <FoldersLists folders={foldersLists}
        parentFolder={parentFolder}
        folderDoubleClick={folderDoubleClickHandle}
        childFolderChangedHandle={childFolderChangedHandle}
      ></FoldersLists>
    </div>
  )
};

export default ImagesFoldersLists;
