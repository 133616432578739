import React, { useState, useEffect } from 'react';

import SingleFolder from '../base_components/folder_single_component';

const createChildFolderPath = (parentFolderName, childFolderName) => {
  // 创建子文件夹路径名称
  try {
    const parentFolderFullNameLength = parentFolderName.split('/').length;
    const childFolderNameSplitSlice = childFolderName.split('/').slice(0, parentFolderFullNameLength);
    const childFolderNameJudgeStr = childFolderNameSplitSlice.join('/');
    console.log(childFolderNameJudgeStr);
    const showName = childFolderName.split('/')[parentFolderFullNameLength];
    if (showName && childFolderNameJudgeStr === parentFolderName) {
      return parentFolderName + `/${showName}`;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

// 图片和文件夹列表
const FoldersLists = ({ folders, parentFolder, folderDoubleClick, childFolderChangedHandle }) => {
  const [showChildreFoldersLists, setShowChildrenFoldersLists] = useState([]);

  useEffect(() => {
    // 初始化文件夹列表
    let retShowFoldersLists = [];
    let childrenFolderNames = [];
    for (let index = 0; index < folders.length; index++) {
      const element = { ...folders[index] };
      const currentChildFolderName = createChildFolderPath(parentFolder, element.folder_name);
      if (currentChildFolderName && !childrenFolderNames.includes(currentChildFolderName)) {
        childrenFolderNames.push(currentChildFolderName);
        retShowFoldersLists.push({
          folderId: element.id,
          parentFolder: parentFolder,
          folderName: currentChildFolderName,
        });
      }
    }
    console.log(retShowFoldersLists);
    setShowChildrenFoldersLists(retShowFoldersLists);

  }, [folders, parentFolder])

  return (
    <div>
      {showChildreFoldersLists.map(item => {
        return (
          <div key={item.folderId.toString()}>
            <SingleFolder
              folderId={item.folderId}
              parentFolderFullName={parentFolder}
              childFolderFullName={item.folderName}
              doubleClick={folderDoubleClick}
              childFolderChangedHandle={childFolderChangedHandle}
            ></SingleFolder>
          </div>
        )
      })}
    </div>
  )
}

FoldersLists.defaultProps = {
  folders: [],
  parentFolder: '',
  folderDoubleClick: (folderId, childFolderFullName, folderShowName) => {
    console.log('doubleClick Function params: (folderId, childFolderFullName, folderShowName)');
    console.log('folderId: 文件夹ID; childFolderFullName: 子文件夹全名; folderShowName: 显示的文件夹名称')
    console.log(folderId, childFolderFullName, folderShowName);
  },
  childFolderChangedHandle: (folderId, childFolderFullName) => {
    // 改变后操作
    console.log(folderId, childFolderFullName);
  },
}

export default FoldersLists;
