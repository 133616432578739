import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Col, Row, Space, Button, Menu, Dropdown, Input } from 'antd';
import { FolderTwoTone } from '@ant-design/icons';

import {
  ImageFoldersRequestPut,
  ImageFoldersRequestDelete
} from './components_requests/folders_requests';

const divContainerStyle = {
  position: 'relative',
  borderRadius: '0.3rem',
  padding: '0.5rem 0.8rem',
  margin: '0.2rem 0rem',
  backgroundColor: 'white'
};

const titleDivStyle = {
  height: '100%',
  width: '100%',
  // border: '3px solid #F00',
  display: 'flex', /*Flex布局*/
  display: '-webkit-flex', /* Safari */
  alignItems: 'center', /*指定垂直居中*/
};

const SingleFolder = ({ folderId, parentFolderFullName, childFolderFullName, doubleClick, childFolderChangedHandle }) => {
  const [folderShowName, setFolderShowName] = useState('');
  const [showEditInput, setShowEditInput] = useState(false);

  const [putRequestCount, setPutRequestCount] = useState(0);
  const putResponseDataHandle = (respObj) => {
    // 更新文件夹标题后操作
    if (Object.keys(respObj).includes('code')
      && respObj.code === 1000) {
      console.log('文件夹名称更新成功...');
      childFolderChangedHandle(folderId, childFolderFullName);
    }
  };
  const [deleteRequestCount, setDeleteRequestCount] = useState(0);
  const deleteResponseDataHandle = (respObj) => {
    // 删除文件夹后操作
    childFolderChangedHandle(folderId, childFolderFullName);
    console.log('删除文件夹操作...; ', respObj);
  }

  const updateFolderName = () => {
    // 更新文件夹名称
    setPutRequestCount(putRequestCount + 1);
    setShowEditInput(false);
  }

  useEffect(() => {
    const parentFolderFullNameSplit = parentFolderFullName.split('/');
    const childFolderFullNameSplit = childFolderFullName.split('/');
    setFolderShowName(childFolderFullNameSplit[parentFolderFullNameSplit.length]);
  }, [parentFolderFullName, childFolderFullName]);

  const folderIconRightMenu = (<Menu>
    <Menu.Item key="folderIcon1" onClick={() => setDeleteRequestCount(deleteRequestCount + 1)}>删除</Menu.Item>
  </Menu>);

  const folderNameRightMenu = (<Menu>
    <Menu.Item key="foldername1" onClick={() => setShowEditInput(true)}>重命名</Menu.Item>
  </Menu>);

  return (
    <div style={divContainerStyle}>
      <Row gutter={{ xs: 4, sm: 6, md: 8 }}>
        <Col span={6}>
          <Dropdown overlay={folderIconRightMenu} trigger={['contextMenu']}>
            <div onDoubleClick={() => doubleClick(folderId, childFolderFullName, folderShowName)}>
              <Avatar shape="square" icon={<FolderTwoTone />} size={60} />
            </div>
          </Dropdown>
        </Col>
        <Col span={18}>
          <Dropdown overlay={folderNameRightMenu} trigger={['contextMenu']}>
            <div style={{ ...titleDivStyle }}>
              {showEditInput
                ? <Input value={folderShowName} onBlur={updateFolderName} onChange={(e) => setFolderShowName(e.target.value)}></Input>
                : <h6>{folderShowName}</h6>}
            </div>
          </Dropdown>
          <ImageFoldersRequestPut
            requestCount={putRequestCount}
            pk={folderId}
            reqObject={{ 'folder_name': `${parentFolderFullName}/${folderShowName}` }}
            responseDataHandle={putResponseDataHandle}></ImageFoldersRequestPut>
          <ImageFoldersRequestDelete
            requestCount={deleteRequestCount}
            pk={folderId}
            responseDataHandle={deleteResponseDataHandle}></ImageFoldersRequestDelete>
        </Col>
      </Row>
    </div>
  )
};

SingleFolder.defaultProps = {
  folderId: 0,
  parentFolderFullName: '',
  childFolderFullName: '/child_path',
  doubleClick: (folderId, childFolderFullName, folderShowName) => {
    console.log('doubleClick Function params: (folderId, childFolderFullName, folderShowName)');
    console.log('folderId: 文件夹ID; childFolderFullName: 子文件夹全名; folderShowName: 显示的文件夹名称')
    console.log(folderId, childFolderFullName, folderShowName);
  },
  childFolderChangedHandle: (folderId, childFolderFullName) => {
    // 改变后操作
    console.log(folderId, childFolderFullName);
  }
};

export default SingleFolder;
