import React, { useState, useRef } from 'react';
import { Modal, Input, message } from 'antd';

import { ImageFoldersRequestPost } from './images_folders_requests';
// 新建文件夹对话框

const CreateFolderModal = ({ parentFolderFullPath, visible, setVisible, createdHandle }) => {
  const newFolderName = useRef('');

  const [requestCount, setRequestCount] = useState('');

  const createFolderHandle = () => {
    if (newFolderName.current.length > 0) {
      setRequestCount(requestCount + 1);
    } else {
      message.error('文件夹名称不能为空');
    }
  }

  const responseDataHandle = (respObj) => {
    if (Object.keys(respObj).includes('code')
      && respObj.code === 1000) {
      setVisible(false);
      createdHandle(newFolderName.current);
    } else {
      message.error('新建文件夹失败...');
    }
  }

  return (
    <>
      <Modal
        title="新建文件夹"
        visible={visible}
        onOk={createFolderHandle}
        onCancel={() => setVisible(false)}>
        <div>
          新文件夹名称
          <Input onChange={e => newFolderName.current = e.target.value}></Input>
        </div>
        <ImageFoldersRequestPost requestCount={requestCount}
          reqObject={{ 'folder_name': `${parentFolderFullPath}/${newFolderName.current}` }}
          responseDataHandle={responseDataHandle}></ImageFoldersRequestPost>
      </Modal>
    </>
  );
}

export default CreateFolderModal;
