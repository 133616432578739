import React from "react";

import NoFooterSiteLayout from '../components/site_layout/no_footer_layout';
import SEO from "../components/seo";

import ImagesFoldersLists from '../components/images/images_folders_list';

const ImagesListPage = () => (
  <NoFooterSiteLayout>
    <SEO title="Images" />
    <ImagesFoldersLists></ImagesFoldersLists>
  </NoFooterSiteLayout>
)

export default ImagesListPage;
