import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'antd';
import { DatabaseFilled } from '@ant-design/icons';

const createBreadcrumbItems = (folderFullPath) => {
  // 生成面包屑的数据
  const array = folderFullPath.split('/');
  // console.log(array);
  let breadcrumbItemsList = [];
  for (let index = 0; index < array.length; index++) {
    const currentFolderName = array[index];
    const currentSliced = array.slice(0, index + 1);
    let currentFolderFullName = currentSliced.join('/');
    // console.log(currentSliced, currentFolderFullName);
    if (currentFolderFullName.length > 1) {
      breadcrumbItemsList.push({
        folderName: currentFolderName,
        folderFullPath: currentFolderFullName
      });
    }
  }
  return breadcrumbItemsList;
}

// 图片文件夹路径面包屑
const PathBreadcrumbs = ({ folderFullPath, clickPathHandle }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbItems(folderFullPath));
  }, [folderFullPath]);

  return (
    <div style={{ width: '100%', height: 'auto', backgroundColor: 'white', padding: '0.1rem 0.5rem', margin: '1rem 0rem', borderRadius: '0.3rem', }}>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => clickPathHandle('')}>
          <DatabaseFilled />
        </Breadcrumb.Item>
        {breadcrumbs.map((item, i) => {
          return (
            <Breadcrumb.Item key={i.toString()} onClick={() => clickPathHandle(item.folderFullPath)}>
              <span>{item.folderName}</span>
            </Breadcrumb.Item>
          )
        })}
      </Breadcrumb>
    </div>
  )
};

export default PathBreadcrumbs;
