import React from 'react';
import AxiosRequest from '../../apis';

// 镜片价格表的request请求

const currentApis = ['images/v1/folders'];

const ImageFoldersRequestGet = ({ requestCount, reqParams, responseDataHandle }) => {
  // 图片文件夹请求 Get
  const api = currentApis[0];
  const method = 'get';
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
ImageFoldersRequestGet.defaultProps = {
  requestCount: 0,
  reqParams: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const ImageFoldersRequestPost = ({ requestCount, reqObject, responseDataHandle }) => {
  // 图片文件夹请求 post
  const api = [].concat(currentApis)[0];
  const method = 'post';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
ImageFoldersRequestPost.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const ImageFoldersRequestPut = ({ requestCount, pk, reqObject, responseDataHandle }) => {
  // 图片文件夹请求 put
  const api = [].concat(currentApis)[0] + `/${pk}`;
  const method = 'put';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
ImageFoldersRequestPut.defaultProps = {
  requestCount: 0,
  pk: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const ImageFoldersRequestDelete = ({ requestCount, pk, responseDataHandle }) => {
  // 图片文件夹请求 put
  const api = [].concat(currentApis)[0] + `/${pk}`;
  const method = 'delete';
  const reqParams = {};
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
ImageFoldersRequestDelete.defaultProps = {
  requestCount: 0,
  pk: 0,
  // reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

export {
  ImageFoldersRequestGet,
  ImageFoldersRequestPost,
  ImageFoldersRequestPut,
  ImageFoldersRequestDelete
};
